import BigNumber from "bignumber.js";
import {CallReturnContext, ContractCallContext, ContractCallResults} from "ethereum-multicall";
import {Constants} from "../../common/constants";
import {ErrorCodes} from "../../common/errors/error-codes";
import {MuteSwitchError} from "../../common/errors/muteswitch-error";
//import { DAI } from '../../common/tokens/dai';
import {ETH_SYMBOL, removeEthFromContractAddress, turnTokenIntoEthForResponse} from "../../common/tokens/eth";
import {USDC} from "../../common/tokens/usdc";
import {USDT} from "../../common/tokens/usdt";
import {KOI, MUTE} from "../../common/tokens/koi";
import {ZKSYNC} from "../../common/tokens";

import {WBTC} from "../../common/tokens/wbtc";
import {WETHContract} from "../../common/tokens/weth";
import {deepClone} from "../../common/utils/deep-clone";
import {formatEther} from "../../common/utils/format-ether";
import {hexlify} from "../../common/utils/hexlify";
import {onlyUnique} from "../../common/utils/only-unique";
import {parseEther} from "../../common/utils/parse-ether";
import {getTradePath} from "../../common/utils/trade-path";
import {CustomMulticall} from "../../custom-multicall";
import {TradePath} from "../../enums/trade-path";
import {EthersProvider} from "../../ethers-provider";
import {muteswitchContracts} from "../../muteswitch-contract-context/get-muteswitch-contracts";
import {koiContracts} from "../../koi-contract-context/get-koi-contracts";

import {MuteSwitchContractContext} from "../../muteswitch-contract-context/muteswitch-contract-context";
import {KoiContractContext} from "../../koi-contract-context/koi-contract-context";
import {TradeDirection} from "../pair/models/trade-direction";
import {MuteSwitchPairSettings} from "../pair/models/muteswitch-pair-settings";
import {AllowanceAndBalanceOf} from "../token/models/allowance-balance-of";
import {Token} from "../token/models/token";
import {TokensFactory} from "../token/tokens.factory";
import {RouterDirection} from "./enums/router-direction";
import {BestRouteQuotes, BestRouteQuotesSmart} from "./models/best-route-quotes";
import {RouteContext, RouteContextV3} from "./models/route-context";
import {RouteQuote, RouteQuoteSmart} from "./models/route-quote";
import {RouteQuoteTradeContext} from "./models/route-quote-trade-context";
import {TokenRoutes, TokenRoutesV3} from "./models/token-routes";

import {getAmountOutFee} from "../../../web3/helper";
import {encodePath} from "../../../web3/v3/liqPositions";

export class MuteSwitchRouterFactory {
  
  private _multicall = new CustomMulticall(
    this._settings?.customNetwork?.nodeUrl!,
    {chainId: this._settings.customNetwork!.chainId, name: this._settings.customNetwork!.nameNetwork},
    this._settings?.customNetwork?.multicallContractAddress,
  );

  /*
  private _MuteSwitchRouterContractFactory = new MuteSwitchRouterContractFactory(
    this._ethersProvider,
    muteswitchContracts.getRouterAddress(this._settings.cloneMuteSwitchContractDetails),
  );
  */

  private _tokensFactory = new TokensFactory(
    this._ethersProvider,
    this._settings.customNetwork,
    this._settings.cloneMuteSwitchContractDetails,
  );

  private _cachePossibleRoutes: RouteContext[] | undefined;
  private _cachePossibleRoutesV3: RouteContextV3[] | undefined;

  public _token0Price;
  public _token1Price;
  public _liqPools;

  public _token0PriceV3;
  public _token1PriceV3;
  public _liqPoolsV3;

  constructor(
    private _ethereumAddress: string,
    public _fromToken: Token,
    public _toToken: Token,
    private _settings: MuteSwitchPairSettings,
    private _ethersProvider: EthersProvider,
  ) {
    _fromToken.decimals = Number(_fromToken.decimals);
    _toToken.decimals = Number(_toToken.decimals);
  }

  /**
   * Get all possible routes will only go up to 4 due to gas increase the more routes
   * you go.
   */
  public async getAllPossibleRoutes(): Promise<RouteContext[]> {
    let findPairs: Token[][][] = [];

    if (this._cachePossibleRoutes) return this._cachePossibleRoutes;

    if (!this._settings.disableMultihops) {
      findPairs = [
        this.mainCurrenciesPairsForFromToken,
        this.mainCurrenciesPairsForToToken,
        this.mainCurrenciesPairsForUSDC,
        this.mainCurrenciesPairsForUSDT,
        this.mainCurrenciesPairsForWETH,
        this.mainCurrenciesPairsForWBTC,
        [[this._fromToken, this._toToken]],
      ];
    } else {
      // multihops turned off so only go direct
      findPairs = [[[this._fromToken, this._toToken]]];
    }

    // console.log(JSON.stringify(findPairs, null, 4));

    const contractCallContext: ContractCallContext[] = [];

    {
      contractCallContext.push({
        reference: "main",
        contractAddress: muteswitchContracts.getPairAddress(this._settings.cloneMuteSwitchContractDetails),
        abi: MuteSwitchContractContext.pairAbi,
        calls: [],
      });

      for (let pairs = 0; pairs < findPairs.length; pairs++) {
        for (let tokenPairs = 0; tokenPairs < findPairs[pairs].length; tokenPairs++) {
          const fromToken = findPairs[pairs][tokenPairs][0];
          const toToken = findPairs[pairs][tokenPairs][1];

          // vol pair
          contractCallContext[0].calls.push({
            reference: `${fromToken.contractAddress}-${toToken.contractAddress}-${fromToken.symbol}/${toToken.symbol}-false`,
            methodName: "getPair",
            methodParameters: [
              removeEthFromContractAddress(fromToken.contractAddress),
              removeEthFromContractAddress(toToken.contractAddress),
              false,
            ],
          });

          //stable pair
          contractCallContext[0].calls.push({
            reference: `${fromToken.contractAddress}-${toToken.contractAddress}-${fromToken.symbol}/${toToken.symbol}-true`,
            methodName: "getPair",
            methodParameters: [
              removeEthFromContractAddress(fromToken.contractAddress),
              removeEthFromContractAddress(toToken.contractAddress),
              true,
            ],
          });
        }
      }
    }

    var allPossibleRoutes: RouteContext[] = [];

    const contractCallResults = await this._multicall.call(contractCallContext);
    {
      const results = contractCallResults.results["main"];

      const availablePairs = results.callsReturnContext.filter(
        (c) => c.returnValues[0] !== "0x0000000000000000000000000000000000000000",
      );

      // console.log(JSON.stringify(results.callsReturnContext, null, 4));

      const fromTokenRoutes: TokenRoutes = {
        token: this._fromToken,
        pairs: {
          fromTokenPairs: this.getTokenAvailablePairs(this._fromToken, availablePairs, RouterDirection.from),
        },
      };

      const toTokenRoutes: TokenRoutes = {
        token: this._toToken,
        pairs: {
          toTokenPairs: this.getTokenAvailablePairs(this._toToken, availablePairs, RouterDirection.to),
        },
      };

      const allMainRoutes: TokenRoutes[] = [];

      for (let i = 0; i < this.allMainTokens.length; i++) {
        const fromTokenPairs = this.getTokenAvailablePairs(this.allMainTokens[i], availablePairs, RouterDirection.from);

        const toTokenPairs = this.getTokenAvailablePairs(this.allMainTokens[i], availablePairs, RouterDirection.to);

        allMainRoutes.push({
          token: this.allMainTokens[i],
          pairs: {
            fromTokenPairs,
            toTokenPairs,
          },
        });
      }

      allPossibleRoutes = this.workOutAllPossibleRoutes(fromTokenRoutes, toTokenRoutes, allMainRoutes);
    }

    // console.log(JSON.stringify(allPossibleRoutes, null, 4));
    this._cachePossibleRoutes = allPossibleRoutes;

    return this._cachePossibleRoutes;
  }

  /**
   * Get all possible routes will only go up to 4 due to gas increase the more routes
   * you go.
   */
  public async getAllPossibleRoutesV3(): Promise<RouteContextV3[]> {
    let findPairs: Token[][][] = [];

    if (this._cachePossibleRoutesV3) return this._cachePossibleRoutesV3;

    if (!this._settings.disableMultihops) {
      findPairs = [
        this.mainCurrenciesPairsForFromToken,
        this.mainCurrenciesPairsForToToken,
        this.mainCurrenciesPairsForUSDC,
        this.mainCurrenciesPairsForUSDT,
        this.mainCurrenciesPairsForWETH,
        this.mainCurrenciesPairsForWBTC,

        [[this._fromToken, this._toToken]],
      ];
    } else {
      // multihops turned off so only go direct
      findPairs = [[[this._fromToken, this._toToken]]];
    }

    // console.log(JSON.stringify(findPairs, null, 4));

    const contractCallContext: ContractCallContext[] = [];

    {
      contractCallContext.push({
        reference: "main",
        contractAddress: koiContracts.getFactoryAddress(this._settings.cloneMuteSwitchContractDetails),
        abi: KoiContractContext.factoryAbi,
        calls: [],
      });

      const feeTiers = ["100", "500", "3000", "5000", "10000"];
      for (let pairs = 0; pairs < findPairs.length; pairs++) {
        for (let tokenPairs = 0; tokenPairs < findPairs[pairs].length; tokenPairs++) {
          const fromToken = findPairs[pairs][tokenPairs][0];
          const toToken = findPairs[pairs][tokenPairs][1];

          for (let i in feeTiers) {
            contractCallContext[0].calls.push({
              reference: `${fromToken.contractAddress}-${toToken.contractAddress}-${fromToken.symbol}/${toToken.symbol}-${feeTiers[i]}`,
              methodName: "getPool",
              methodParameters: [
                removeEthFromContractAddress(fromToken.contractAddress),
                removeEthFromContractAddress(toToken.contractAddress),
                feeTiers[i],
              ],
            });
          }
        }
      }
    }

    var allPossibleRoutes: RouteContextV3[] = [];

    const contractCallResults = await this._multicall.call(contractCallContext);
    {
      const results = contractCallResults.results["main"];

      const availablePairs = results.callsReturnContext.filter(
        (c) => {
          var suc = false
          for(let i in this._liqPoolsV3){
            if(this._liqPoolsV3[i].id.toLowerCase() == c.returnValues[0].toLowerCase())
                suc = true
          }
          if(c.returnValues[0] == "0x0000000000000000000000000000000000000000")
              return false

          return suc
        },
      );

      // console.log(JSON.stringify(results.callsReturnContext, null, 4));

      var _from = this.getTokenAvailablePairsV3(this._fromToken, availablePairs, RouterDirection.from);
      var _to = this.getTokenAvailablePairsV3(this._toToken, availablePairs, RouterDirection.to);

      _from = _from.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          _from.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });

      _to = _to.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          _to.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });

      const fromTokenRoutes: TokenRoutesV3 = {
        token: this._fromToken,
        pairs: {
          fromTokenPairs: _from,
        },
      };

      const toTokenRoutes: TokenRoutesV3 = {
        token: this._toToken,
        pairs: {
          toTokenPairs: _to,
        },
      };

      const allMainRoutes: TokenRoutesV3[] = [];

      for (let i = 0; i < this.allMainTokens.length; i++) {
        var fromTokenPairs = this.getTokenAvailablePairsV3(this.allMainTokens[i], availablePairs, RouterDirection.from);
        var toTokenPairs = this.getTokenAvailablePairsV3(this.allMainTokens[i], availablePairs, RouterDirection.to);

        fromTokenPairs = fromTokenPairs.filter((value, index) => {
          const _value = JSON.stringify(value);
          return (
            index ===
            fromTokenPairs.findIndex((obj) => {
              return JSON.stringify(obj) === _value;
            })
          );
        });

        toTokenPairs = toTokenPairs.filter((value, index) => {
          const _value = JSON.stringify(value);
          return (
            index ===
            toTokenPairs.findIndex((obj) => {
              return JSON.stringify(obj) === _value;
            })
          );
        });

        allMainRoutes.push({
          token: this.allMainTokens[i],
          pairs: {
            fromTokenPairs: fromTokenPairs,
            toTokenPairs: toTokenPairs,
          },
        });
      }

      allPossibleRoutes = this.workOutAllPossibleRoutesV3(fromTokenRoutes, toTokenRoutes, allMainRoutes);
    }

    // console.log(JSON.stringify(allPossibleRoutes, null, 4));
    this._cachePossibleRoutesV3 = allPossibleRoutes;

    return this._cachePossibleRoutesV3;
  }

  /**
   * Get all possible routes with the quotes
   * @param amountToTrade The amount to trade
   * @param direction The direction you want to get the quote from
   */
  public async getAllPossibleRoutesWithQuotes(
    amountToTrade: BigNumber,
    direction: TradeDirection,
  ): Promise<RouteQuote[]> {
    const tradeAmount = this.formatAmountToTrade(amountToTrade, direction);

    const routes = await this.getAllPossibleRoutes();

    const contractCallContext: ContractCallContext<RouteContext[]>[] = [];
    {
      contractCallContext.push({
        reference: "main",
        contractAddress: muteswitchContracts.getRouterAddress(this._settings.cloneMuteSwitchContractDetails),
        abi: MuteSwitchContractContext.routerAbi,
        calls: [],
        context: routes,
      });

      for (let i = 0; i < routes.length; i++) {
        const routeCombo = routes[i].route.map((c) => {
          return removeEthFromContractAddress(c.contractAddress);
        });

        contractCallContext[0].calls.push({
          reference: `route${i}`,
          methodName: direction === TradeDirection.input ? "getAmountsOutExpanded" : "getAmountsIn",
          methodParameters: [tradeAmount, routeCombo],
        });

        /*
        //ignore stable pools (tend to break with tiny amounts)
        if(amountToTrade.lte(0.1)){
          contractCallContext[0].calls.push({
              reference: `route${i}`,
              methodName: direction === TradeDirection.input
                  ? 'getAmountsOut'
                  : 'getAmountsIn',
              methodParameters: [tradeAmount, routeCombo, [false, false, false, false]],
          });
      } else {
          contractCallContext[0].calls.push({
              reference: `route${i}`,
              methodName: direction === TradeDirection.input
                  ? 'getAmountsOutExpanded'
                  : 'getAmountsIn',
              methodParameters: [tradeAmount, routeCombo],
          });
      }
      */
      }
    }

    const contractCallResults = await this._multicall.call(contractCallContext);
    const tradeResults = this.buildRouteQuotesFromResults(amountToTrade, contractCallResults, direction);

    return tradeResults;
  }

  public findPathCalculation(amountIn, top_pairs, _token0, _token1) {
    var stable = new BigNumber(0);
    var normal = new BigNumber(0);
    var stable_fee = new BigNumber(0);
    var normal_fee = new BigNumber(0);

    for (let i in top_pairs) {
      let tokenA = top_pairs[i].token0.id;
      let tokenB = top_pairs[i].token1.id;

      //matching pair
      if ((tokenA == _token0 || tokenB == _token0) && (tokenA == _token1 || tokenB == _token1)) {
        let reserveA = _token0 == tokenA ? top_pairs[i].reserve0 : top_pairs[i].reserve1;
        let reserveB = _token0 == tokenA ? top_pairs[i].reserve1 : top_pairs[i].reserve0;

        if (top_pairs[i].stable) {
          stable = getAmountOutFee(amountIn, reserveA, reserveB, top_pairs[i].stable, top_pairs[i].pairFee);
          stable_fee = new BigNumber(top_pairs[i].pairFee);
        } else {
          normal = getAmountOutFee(amountIn, reserveA, reserveB, top_pairs[i].stable, top_pairs[i].pairFee);
          normal_fee = new BigNumber(top_pairs[i].pairFee);
        }
      }
    }

    if (normal.gt(stable)) {
      return {stable: false, amount: normal, fee: normal_fee};
    } else {
      return {stable: true, amount: stable, fee: stable_fee};
    }
  }

  public getBestRouting(am_mappings): RouteQuoteSmart {    
    let bestAm = new BigNumber(0);

    var bestResult = {
      amount: bestAm.toFixed(),
      amountIn: new BigNumber(0).toFixed(),
      trade: {},
      fee: new BigNumber(0).toFixed(),
    };
    for (let i in am_mappings) {
      for (let j in am_mappings[i]) {
        let _trade = am_mappings[i][j];
        if (i == "100") {
          if (new BigNumber(_trade.amOut).gt(bestAm)) {
            bestAm = new BigNumber(_trade.amOut);
            bestResult = {
              amount: _trade.amOut,
              amountIn: new BigNumber(_trade.amIn).toFixed(),
              trade: {
                "100": _trade,
              },
              fee: new BigNumber(_trade.fee).toFixed(),
            };
          }
        } else {
          let _trade_compliments = am_mappings[new BigNumber(100).minus(i).toFixed(0)];

          for (let k in _trade_compliments) {
            if (JSON.stringify(_trade_compliments[k].route) != JSON.stringify(_trade.route)) {
              if (new BigNumber(_trade.amOut).plus(_trade_compliments[k].amOut).gt(bestAm)) {
                bestAm = new BigNumber(_trade.amOut).plus(_trade_compliments[k].amOut);
                bestResult = {
                  amount: new BigNumber(_trade.amOut).plus(_trade_compliments[k].amOut).toFixed(),
                  amountIn: new BigNumber(_trade.amIn).plus(_trade_compliments[k].amIn).toFixed(),
                  trade: {},
                  fee: new BigNumber(_trade.fee)
                    .times(i)
                    .plus(new BigNumber(_trade_compliments[k].fee).times(new BigNumber(100).minus(i).toFixed(0)))
                    .div(100)
                    .toFixed(),
                };
                const is50 = new BigNumber(i).eq(50);
                bestResult.trade[i + (is50 ? "_0" : "")] = _trade;
                bestResult.trade[new BigNumber(100).minus(i).toFixed(0) + (is50 ? "_1" : "")] = _trade_compliments[k];
              }
            }
          }
        }
      }
    }

    return bestResult;
  }

  /**
   * Get all possible routes with the quotes
   * @param amountToTrade The amount to trade
   * @param direction The direction you want to get the quote from
   */
  public async getAllPossibleRoutesWithQuotesSmart(
    amountToTrade: BigNumber,
    direction: TradeDirection,
  ): Promise<RouteQuoteSmart> {
    const tradeAmount = this.formatAmountToTrade(amountToTrade, direction);

    var prom_array = await Promise.all([await this.getAllPossibleRoutes(), await this.getAllPossibleRoutesV3()]);

    var routes = prom_array[0];
    var routes_v3 = prom_array[1];

    const contractCallContext: ContractCallContext[] = [];

    contractCallContext.push({
      reference: "v3",
      contractAddress: koiContracts.getQuoterAddress(this._settings.cloneMuteSwitchContractDetails),
      abi: KoiContractContext.quoterAbi,
      calls: [],
    });

    //quoteExactInput
    //const splits = [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]
    const splits = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    //const splits = [20, 40, 60, 80, 100];

    routes_v3 = routes_v3.filter((value, index) => {
      const _value = JSON.stringify(value.route);
      return (
        index ===
        routes_v3.findIndex((obj) => {
          return JSON.stringify(obj.route) === _value;
        })
      );
    });

    for (let i in routes_v3) {
      var _route = routes_v3[i];
      var encodedPath = encodePath(_route.route);

      for (let k in splits) {
        var _amount = new BigNumber(splits[k]).times(tradeAmount).div(100).toFixed(0);
        contractCallContext[0].calls.push({
          reference: JSON.stringify(_route.route) + `_split${splits[k]}`,
          methodName: "quoteExactInput",
          methodParameters: [encodedPath, _amount],
        });
      }
    }

    var dupes = {};

    var _myTrades: any = [];
    for (let i = 0; i < routes.length; i++) {
      const routeCombo = routes[i].route.map((c) => {
        return removeEthFromContractAddress(c.contractAddress);
      });

      if (dupes[JSON.stringify(routeCombo)]) {
        continue;
      }

      dupes[JSON.stringify(routeCombo)] = true;

      for (let j in splits) {
        var _am = new BigNumber(splits[j]).times(amountToTrade).div(100).toFixed();
        var _tradeAmount = new BigNumber(splits[j]).times(amountToTrade).div(100).toFixed();
        var stables: any = [];
        var cum_fee = new BigNumber(0);

        var routing: {
          from: string;
          to: string;
          stable: boolean;
          fee: string;
        }[] = [];

        for (let _pair = 0; _pair < routeCombo.length - 1; _pair++) {
          var _token0 = routeCombo[_pair].toLowerCase();
          var _token1 = routeCombo[_pair + 1].toLowerCase();
          var resTrade = this.findPathCalculation(_tradeAmount, this._liqPools, _token0, _token1);
          _tradeAmount = resTrade.amount.toFixed();
          stables.push(resTrade.stable);
          cum_fee = cum_fee.plus(resTrade.fee);

          routing.push({from: _token0, to: _token1, stable: resTrade.stable, fee: resTrade.fee.toFixed()});
        }

        _myTrades.push({
          ratio: splits[j],
          amountIn: _am,
          routeCombo,
          route: routing,
          amount: _tradeAmount,
          fee: cum_fee.toFixed(),
        });
      }
    }

    var am_mappings = {};
    for (let i in _myTrades) {
      let ratio = _myTrades[i].ratio;

      if (!am_mappings[ratio]) am_mappings[ratio] = [];

      am_mappings[ratio].push({
        route: _myTrades[i].route,
        amOut: new BigNumber(_myTrades[i].amount).toFixed(),
        amIn: new BigNumber(_myTrades[i].amountIn).toFixed(),
        fee: _myTrades[i].fee,
        v2: true,
      });
    }

    try {
      var contractCallResults = await this._multicall.call(contractCallContext);
      if(Object.keys(contractCallResults.results).length > 0)
        for (let i in contractCallResults.results.v3.callsReturnContext) {
          var _v3Trade = contractCallResults.results.v3.callsReturnContext[i];
          var _amIn = contractCallResults.results.v3.originalContractCallContext.calls[i].methodParameters[1];

          var _path: {
            from: Token;
            to: Token;
            fee: Number;
          }[] = JSON.parse(_v3Trade.reference.split("_split")[0]);

          var _ratio = _v3Trade.reference.split("_split")[1];

          if (!am_mappings[_ratio]) am_mappings[_ratio] = [];

          am_mappings[_ratio].push({
            route: _path,
            amOut: new BigNumber(_v3Trade.returnValues[0]).div(Math.pow(10, this._toToken.decimals)).toFixed(),
            amIn: new BigNumber(_amIn).div(Math.pow(10, this._fromToken.decimals)).toFixed(),
            fee: 0,
            v3: true,
          });
        }
    } catch (e) {
      console.log(e);
    }

    var routeResults = this.getBestRouting(am_mappings);
    var fromAm = new BigNumber(this._token0Price.derivedETH).times(amountToTrade);
    var toAm = new BigNumber(this._token1Price.derivedETH).times(routeResults.amount);

    var poolFee = new BigNumber(routeResults.fee!).div(100);

    var slippage = new BigNumber(1).minus(toAm.div(fromAm)).times(100).minus(poolFee).toFixed();

    return {
      ...routeResults,
      slippage,
    };
  }

  /**
   * Finds the best route
   * @param amountToTrade The amount they want to trade
   * @param direction The direction you want to get the quote from
   */
  public async findBestRoute(amountToTrade: BigNumber, direction: TradeDirection): Promise<BestRouteQuotes> {
    let allRoutes = await this.getAllPossibleRoutesWithQuotes(amountToTrade, direction);

    if (allRoutes.length === 0) {
      throw new MuteSwitchError(
        `No routes found for ${this._fromToken.symbol} > ${this._toToken.symbol}`,
        ErrorCodes.noRoutesFound,
      );
    }

    const allowanceAndBalances = await this.hasEnoughAllowanceAndBalance(
      amountToTrade,
      allRoutes[0].expectedConvertQuote,
      direction,
    );

    return {
      bestRouteQuote: allRoutes[0],
      triedRoutesQuote: allRoutes.map((route) => {
        return {
          expectedConvertQuote: route.expectedConvertQuote,
          expectedConvertQuoteOrTokenAmountInMaxWithSlippage: route.expectedConvertQuoteOrTokenAmountInMaxWithSlippage,
          transaction: route.transaction,
          tradeExpires: route.tradeExpires,
          routePathArrayTokenMap: route.routePathArrayTokenMap,
          routeText: route.routeText,
          expectedAmounts: route.expectedAmounts,
          routePathArray: route.routePathArray,
          liquidityProviderFee: route.liquidityProviderFee,
          stable: route.stable,
          quoteDirection: route.quoteDirection,
          gasPriceEstimatedBy: route.gasPriceEstimatedBy,
        };
      }),
      hasEnoughBalance: allowanceAndBalances.enoughBalance,
      fromAllowance: allowanceAndBalances.fromAllowance,
      fromBalance: allowanceAndBalances.fromBalance,
      toBalance: allowanceAndBalances.toBalance,
      hasEnoughAllowance: allowanceAndBalances.enoughAllowance,
    };
  }

  /**
   * Finds the best route
   * @param amountToTrade The amount they want to trade
   * @param direction The direction you want to get the quote from
   */
  public async findBestRouteSmart(amountToTrade: BigNumber, direction: TradeDirection): Promise<BestRouteQuotesSmart> {
    let routing = await this.getAllPossibleRoutesWithQuotesSmart(amountToTrade, direction);

    if (new BigNumber(routing.amount).eq(0)) {
      throw new MuteSwitchError(
        `No routes found for ${this._fromToken.symbol} > ${this._toToken.symbol}`,
        ErrorCodes.noRoutesFound,
      );
    }

    const allowanceAndBalances = await this.hasEnoughAllowanceAndBalance(
      amountToTrade,
      amountToTrade.toFixed(),
      direction,
    );

    return {
      bestRouteQuote: routing.amount,
      routes: routing.trade,
      impact: routing.slippage,
      hasEnoughBalance: allowanceAndBalances.enoughBalance,
      fromAllowance: allowanceAndBalances.fromAllowance,
      fromBalance: allowanceAndBalances.fromBalance,
      toBalance: allowanceAndBalances.toBalance,
      hasEnoughAllowance: allowanceAndBalances.enoughAllowance,
    };
  }

  /**
   * Generates the trade datetime unix time
   */
  public generateTradeDeadlineUnixTime(): number {
    const now = new Date();
    const expiryDate = new Date(now.getTime() + this._settings.deadlineMinutes * 60000);
    return (expiryDate.getTime() / 1e3) | 0;
  }

  /**
   * Get eth balance
   */
  public async getEthBalance(): Promise<BigNumber> {
    const balance = await this._ethersProvider.balanceOf(this._ethereumAddress);

    return new BigNumber(balance).shiftedBy(Constants.ETH_MAX_DECIMALS * -1);
  }

  /**
   * Get the allowance and balance for the from and to token (will get balance for eth as well)
   */
  private async getAllowanceAndBalanceForTokens(): Promise<{
    fromToken: AllowanceAndBalanceOf;
    toToken: AllowanceAndBalanceOf;
  }> {
    const allowanceAndBalanceOfForTokens = await this._tokensFactory.getAllowanceAndBalanceOfForContracts(
      this._ethereumAddress,
      [this._fromToken.contractAddress, this._toToken.contractAddress],
      false,
    );

    return {
      fromToken: allowanceAndBalanceOfForTokens.find(
        (c) => c.token.contractAddress.toLowerCase() === this._fromToken.contractAddress.toLowerCase(),
      )!.allowanceAndBalanceOf,
      toToken: allowanceAndBalanceOfForTokens.find(
        (c) => c.token.contractAddress.toLowerCase() === this._toToken.contractAddress.toLowerCase(),
      )!.allowanceAndBalanceOf,
    };
  }

  /**
   * Has got enough allowance to do the trade
   * @param amount The amount you want to swap
   */
  private hasGotEnoughAllowance(amount: string, allowance: string): boolean {
    if (this.tradePath() === TradePath.ethToErc20) {
      return true;
    }

    const bigNumberAllowance = new BigNumber(allowance).shiftedBy(this._fromToken.decimals * -1);

    if (new BigNumber(amount).isGreaterThan(bigNumberAllowance)) {
      return false;
    }

    return true;
  }

  private async hasEnoughAllowanceAndBalance(
    amountToTrade: BigNumber,
    amountIn: string,
    direction: TradeDirection,
  ): Promise<{
    enoughBalance: boolean;
    fromBalance: string;
    toBalance: string;
    enoughAllowance: boolean;
    fromAllowance: string;
  }> {
    const allowanceAndBalancesForTokens = await this.getAllowanceAndBalanceForTokens();
    amountToTrade = new BigNumber(amountToTrade);
    let enoughBalance = false;
    let fromBalance = allowanceAndBalancesForTokens.fromToken.balanceOf;
    let fromAllowance = new BigNumber(allowanceAndBalancesForTokens.fromToken.allowance).toString();

    switch (this.tradePath()) {
      case TradePath.ethToErc20:
        const result = await this.hasGotEnoughBalanceEth(
          direction === TradeDirection.input ? amountToTrade.toFixed() : amountIn,
        );
        enoughBalance = result.hasEnough;
        fromBalance = result.balance;
        fromAllowance = new BigNumber(Math.pow(10, 28)).toString();
        break;
      case TradePath.erc20ToErc20:
      case TradePath.erc20ToEth:
        if (direction == TradeDirection.input) {
          const result = this.hasGotEnoughBalanceErc20(
            amountToTrade.toFixed(),
            allowanceAndBalancesForTokens.fromToken.balanceOf,
          );

          enoughBalance = result.hasEnough;
          fromBalance = result.balance;
          fromAllowance = new BigNumber(allowanceAndBalancesForTokens.fromToken.allowance).toString();
        } else {
          const result = this.hasGotEnoughBalanceErc20(amountIn, allowanceAndBalancesForTokens.fromToken.balanceOf);

          enoughBalance = result.hasEnough;
          fromBalance = result.balance;
          fromAllowance = new BigNumber(allowanceAndBalancesForTokens.fromToken.allowance).toString();
        }
    }

    const enoughAllowance =
      direction === TradeDirection.input
        ? this.hasGotEnoughAllowance(amountToTrade.toFixed(), allowanceAndBalancesForTokens.fromToken.allowance)
        : this.hasGotEnoughAllowance(amountIn, allowanceAndBalancesForTokens.fromToken.allowance);

    return {
      enoughAllowance,
      fromAllowance,
      enoughBalance,
      fromBalance,
      toBalance: allowanceAndBalancesForTokens.toToken.balanceOf,
    };
  }

  /**
   * Has got enough balance to do the trade (eth check only)
   * @param amount The amount you want to swap
   */
  private async hasGotEnoughBalanceEth(amount: string): Promise<{
    hasEnough: boolean;
    balance: string;
  }> {
    const balance = await this.getEthBalance();

    if (new BigNumber(amount).isGreaterThan(balance)) {
      return {
        hasEnough: false,
        balance: balance.toFixed(),
      };
    }

    return {
      hasEnough: true,
      balance: balance.toFixed(),
    };
  }

  /**
   * Has got enough balance to do the trade (erc20 check only)
   * @param amount The amount you want to swap
   */
  private hasGotEnoughBalanceErc20(
    amount: string,
    balance: string,
  ): {
    hasEnough: boolean;
    balance: string;
  } {
    const bigNumberBalance = new BigNumber(balance).shiftedBy(this._fromToken.decimals * -1);

    if (new BigNumber(amount).isGreaterThan(bigNumberBalance)) {
      return {
        hasEnough: false,
        balance: bigNumberBalance.toFixed(),
      };
    }

    return {
      hasEnough: true,
      balance: bigNumberBalance.toFixed(),
    };
  }

  /**
   * Work out the best route quote hops aka the best direct, the best 3 hop and the best 4 hop
   * @param allRoutes All the routes
   * @param enoughAllowance Has got enough allowance
   */
  private getBestRouteQuotesHops(allRoutes: RouteQuote[], enoughAllowance: boolean): RouteQuote[] {
    const routes: RouteQuote[] = [];
    for (let i = 0; i < allRoutes.length; i++) {
      if (
        routes.find((r) => r.routePathArray.length === 2) &&
        routes.find((r) => r.routePathArray.length === 3) &&
        routes.find((r) => r.routePathArray.length === 4)
      ) {
        break;
      }

      const route = allRoutes[i];
      if (enoughAllowance) {
        if (route.routePathArray.length === 2 && !routes.find((r) => r.routePathArray.length === 2)) {
          routes.push(route);
          continue;
        }

        if (route.routePathArray.length === 3 && !routes.find((r) => r.routePathArray.length === 3)) {
          routes.push(route);
          continue;
        }

        if (route.routePathArray.length === 4 && !routes.find((r) => r.routePathArray.length === 4)) {
          routes.push(route);
          continue;
        }
      }
    }

    return routes;
  }

  /**
   * Works out every possible route it can take
   * @param fromTokenRoutes The from token routes
   * @param toTokenRoutes The to token routes
   * @param allMainRoutes All the main routes
   */
  private workOutAllPossibleRoutes(
    fromTokenRoutes: TokenRoutes,
    toTokenRoutes: TokenRoutes,
    allMainRoutes: TokenRoutes[],
  ): RouteContext[] {
    const jointCompatibleRoutes = toTokenRoutes.pairs.toTokenPairs!.filter((t) =>
      fromTokenRoutes.pairs.fromTokenPairs!.find(
        (f) => f.contractAddress.toLowerCase() === t.contractAddress.toLowerCase(),
      ),
    );

    const routes: RouteContext[] = [];
    if (
      fromTokenRoutes.pairs.fromTokenPairs!.find(
        (t) => t.contractAddress.toLowerCase() === toTokenRoutes.token.contractAddress.toLowerCase(),
      )
    ) {
      routes.push({
        route: [fromTokenRoutes.token, toTokenRoutes.token],
      });
    }

    for (let i = 0; i < allMainRoutes.length; i++) {
      const tokenRoute = allMainRoutes[i];
      if (
        jointCompatibleRoutes.find(
          (c) => c.contractAddress.toLowerCase() === tokenRoute.token.contractAddress.toLowerCase(),
        )
      ) {
        routes.push({
          route: [fromTokenRoutes.token, tokenRoute.token, toTokenRoutes.token],
        });

        for (let f = 0; f < fromTokenRoutes.pairs.fromTokenPairs!.length; f++) {
          const fromSupportedToken = fromTokenRoutes.pairs.fromTokenPairs![f];
          if (
            tokenRoute.pairs.toTokenPairs!.find(
              (pair) => pair.contractAddress.toLowerCase() === fromSupportedToken.contractAddress.toLowerCase(),
            )
          ) {
            const workedOutFromRoute = [
              fromTokenRoutes.token,
              fromSupportedToken,
              tokenRoute.token,
              toTokenRoutes.token,
            ];
            if (workedOutFromRoute.filter(onlyUnique).length === workedOutFromRoute.length) {
              routes.push({
                route: workedOutFromRoute,
              });
            }
          }
        }

        for (let f = 0; f < toTokenRoutes.pairs.toTokenPairs!.length; f++) {
          const toSupportedToken = toTokenRoutes.pairs.toTokenPairs![f];
          if (
            tokenRoute.pairs.fromTokenPairs!.find(
              (pair) => pair.contractAddress.toLowerCase() === toSupportedToken.contractAddress.toLowerCase(),
            )
          ) {
            const workedOutToRoute = [fromTokenRoutes.token, tokenRoute.token, toSupportedToken, toTokenRoutes.token];

            if (workedOutToRoute.filter(onlyUnique).length === workedOutToRoute.length) {
              routes.push({
                route: workedOutToRoute,
              });
            }
          }
        }
      }
    }

    return routes;
  }

  /**
   * Works out every possible route it can take
   * @param fromTokenRoutes The from token routes
   * @param toTokenRoutes The to token routes
   * @param allMainRoutes All the main routes
   */
  private workOutAllPossibleRoutesV3(
    fromTokenRoutes: TokenRoutesV3,
    toTokenRoutes: TokenRoutesV3,
    allMainRoutes: TokenRoutesV3[],
  ): RouteContextV3[] {
    const routes: RouteContextV3[] = [];

    /*
    console.log(fromTokenRoutes)
    console.log(toTokenRoutes)
    console.log(allMainRoutes)
    */

    for (let i in fromTokenRoutes.pairs!.fromTokenPairs!) {
      let _from = fromTokenRoutes!.pairs!.fromTokenPairs[i]!;

      if (_from.to.contractAddress.toLowerCase() == toTokenRoutes.token.contractAddress.toLowerCase()) {
        routes.push({
          route: [
            {
              from: fromTokenRoutes.token,
              to: toTokenRoutes.token,
              fee: _from.fee,
            },
          ],
        });
      }

      for (let j in toTokenRoutes.pairs!.toTokenPairs!) {
        let _to = toTokenRoutes!.pairs!.toTokenPairs[j]!;

        if (_from.to.contractAddress.toLowerCase() == _to.from.contractAddress.toLowerCase()) {
          routes.push({
            route: [
              {
                from: _from.from,
                to: _from.to,
                fee: _from.fee,
              },
              {
                from: _to.from,
                to: _to.to,
                fee: _to.fee,
              },
            ],
          });
        }
      }
    }

    return routes;
    /*
  
    fromTokenRoutes.
    for(let i in fromTokenRoutes.pairs.fromTokenPairs){
      let _from: {from: Token, to: Token, fee: Number} = fromTokenRoutes.pairs!.fromTokenPairs[i]!
  
      if(_from.from.contractAddress.toLowerCase() == toTokenRoutes.token.contractAddress.toLowerCase()){
  
        fromTokenRoutes.token, toTokenRoutes.token
        routes.push({
          route: [
            from: _from.from, 
            to: Token, 
            feeTiers: Number
          ]
        })
      }
      
      for(let j in toTokenRoutes.pairs.toTokenPairs){
        let _to: {from: Token, to: Token, fee: Number} = toTokenRoutes.pairs.toTokenPairs[j]!
  
  
  
        for(let k in allMainRoutes){
          let _main = allMainRoutes[k].pairs
  
          for(let l in _main){
            let _mainPairs = _main[l]
  
          }
  
  
        }
      }
  
    }
    const jointCompatibleRoutes = toTokenRoutes.pairs.toTokenPairs!.filter((t) =>
      fromTokenRoutes.pairs.fromTokenPairs!.find(
        (f) => f.from.contractAddress.toLowerCase() === t.to.contractAddress.toLowerCase(),
      ),
    );
  
    const routes: RouteContext[] = [];
    if (
      fromTokenRoutes.pairs.fromTokenPairs!.find(
        (t) => t.contractAddress.toLowerCase() === toTokenRoutes.token.contractAddress.toLowerCase(),
      )
    ) {
      routes.push({
        route: [fromTokenRoutes.token, toTokenRoutes.token],
      });
    }
  
    for (let i = 0; i < allMainRoutes.length; i++) {
      const tokenRoute = allMainRoutes[i];
      if (
        jointCompatibleRoutes.find(
          (c) => c.contractAddress.toLowerCase() === tokenRoute.token.contractAddress.toLowerCase(),
        )
      ) {
        routes.push({
          route: [fromTokenRoutes.token, tokenRoute.token, toTokenRoutes.token],
        });
  
        for (let f = 0; f < fromTokenRoutes.pairs.fromTokenPairs!.length; f++) {
          const fromSupportedToken = fromTokenRoutes.pairs.fromTokenPairs![f];
          if (
            tokenRoute.pairs.toTokenPairs!.find(
              (pair) => pair.contractAddress.toLowerCase() === fromSupportedToken.contractAddress.toLowerCase(),
            )
          ) {
            const workedOutFromRoute = [
              fromTokenRoutes.token,
              fromSupportedToken,
              tokenRoute.token,
              toTokenRoutes.token,
            ];
            if (workedOutFromRoute.filter(onlyUnique).length === workedOutFromRoute.length) {
              routes.push({
                route: workedOutFromRoute,
              });
            }
          }
        }
  
        for (let f = 0; f < toTokenRoutes.pairs.toTokenPairs!.length; f++) {
          const toSupportedToken = toTokenRoutes.pairs.toTokenPairs![f];
          if (
            tokenRoute.pairs.fromTokenPairs!.find(
              (pair) => pair.contractAddress.toLowerCase() === toSupportedToken.contractAddress.toLowerCase(),
            )
          ) {
            const workedOutToRoute = [fromTokenRoutes.token, tokenRoute.token, toSupportedToken, toTokenRoutes.token];
  
            if (workedOutToRoute.filter(onlyUnique).length === workedOutToRoute.length) {
              routes.push({
                route: workedOutToRoute,
              });
            }
          }
        }
      }
    }
  
    return routes;
    */
  }

  private getTokenAvailablePairs(token: Token, allAvailablePairs: CallReturnContext[], direction: RouterDirection) {
    switch (direction) {
      case RouterDirection.from:
        return this.getFromRouterDirectionAvailablePairs(token, allAvailablePairs);
      case RouterDirection.to:
        return this.getToRouterDirectionAvailablePairs(token, allAvailablePairs);
    }
  }

  private getTokenAvailablePairsV3(token: Token, allAvailablePairs: CallReturnContext[], direction: RouterDirection) {
    switch (direction) {
      case RouterDirection.from:
        return this.getFromRouterDirectionAvailablePairsV3(token, allAvailablePairs);
      case RouterDirection.to:
        return this.getToRouterDirectionAvailablePairsV3(token, allAvailablePairs);
    }
  }

  private getFromRouterDirectionAvailablePairs(token: Token, allAvailablePairs: CallReturnContext[]): Token[] {
    const fromRouterDirection = allAvailablePairs.filter((c) => c.reference.split("-")[0] === token.contractAddress);
    const tokens: Token[] = [];
    const stable: boolean[] = [];

    for (let index = 0; index < fromRouterDirection.length; index++) {
      const context = fromRouterDirection[index];

      tokens.push(this.allTokens.find((t) => t.contractAddress === context.reference.split("-")[1])!);

      stable.push(context.reference.split("-")[3] == "true");
    }

    return tokens;
  }

  private getToRouterDirectionAvailablePairs(token: Token, allAvailablePairs: CallReturnContext[]): Token[] {
    const toRouterDirection = allAvailablePairs.filter((c) => c.reference.split("-")[1] === token.contractAddress);
    const tokens: Token[] = [];
    const stable: boolean[] = [];

    for (let index = 0; index < toRouterDirection.length; index++) {
      const context = toRouterDirection[index];
      tokens.push(this.allTokens.find((t) => t.contractAddress === context.reference.split("-")[0])!);
      stable.push(context.reference.split("-")[3] == "true");
    }

    return tokens;
  }

  private getFromRouterDirectionAvailablePairsV3(
    token: Token,
    allAvailablePairs: CallReturnContext[],
  ): {from: Token; to: Token; fee: Number}[] {
    const fromRouterDirection = allAvailablePairs.filter((c) => c.reference.split("-")[0] === token.contractAddress);
    const pools: {from: Token; to: Token; fee: Number}[] = [];

    for (let index = 0; index < fromRouterDirection.length; index++) {
      const context = fromRouterDirection[index];
      pools.push({
        from: this.allTokens.find((t) => t.contractAddress === context.reference.split("-")[0])!,
        to: this.allTokens.find((t) => t.contractAddress === context.reference.split("-")[1])!,
        fee: Number(context.reference.split("-")[3]),
      });
    }

    return pools;
  }

  private getToRouterDirectionAvailablePairsV3(
    token: Token,
    allAvailablePairs: CallReturnContext[],
  ): {from: Token; to: Token; fee: Number}[] {
    const toRouterDirection = allAvailablePairs.filter((c) => c.reference.split("-")[1] === token.contractAddress);
    const pools: {from: Token; to: Token; fee: Number}[] = [];

    for (let index = 0; index < toRouterDirection.length; index++) {
      const context = toRouterDirection[index];
      pools.push({
        from: this.allTokens.find((t) => t.contractAddress === context.reference.split("-")[0])!,
        to: this.allTokens.find((t) => t.contractAddress === context.reference.split("-")[1])!,
        fee: Number(context.reference.split("-")[3]),
      });
    }

    return pools;
  }

  /**
   * Build up route quotes from results
   * @param contractCallResults The contract call results
   * @param direction The direction you want to get the quote from
   */
  private buildRouteQuotesFromResults(
    amountToTrade: BigNumber,
    contractCallResults: ContractCallResults,
    direction: TradeDirection,
  ): RouteQuote[] {
    amountToTrade = new BigNumber(amountToTrade);

    const tradePath = this.tradePath();

    const result: RouteQuote[] = [];
    for (const key in contractCallResults.results) {
      const contractCallReturnContext = contractCallResults.results[key];
      if (contractCallReturnContext) {
        for (let i = 0; i < contractCallReturnContext.callsReturnContext.length; i++) {
          const callReturnContext = contractCallReturnContext.callsReturnContext[i];

          if (!callReturnContext.success) {
            continue;
          }

          switch (tradePath) {
            case TradePath.ethToErc20:
              result.push(
                this.buildRouteQuoteForEthToErc20(
                  amountToTrade,
                  callReturnContext,
                  contractCallReturnContext.originalContractCallContext.context[i],
                  direction,
                ),
              );
              break;
            case TradePath.erc20ToEth:
              result.push(
                this.buildRouteQuoteForErc20ToEth(
                  amountToTrade,
                  callReturnContext,
                  contractCallReturnContext.originalContractCallContext.context[i],
                  direction,
                ),
              );
              break;
            case TradePath.erc20ToErc20:
              result.push(
                this.buildRouteQuoteForErc20ToErc20(
                  amountToTrade,
                  callReturnContext,
                  contractCallReturnContext.originalContractCallContext.context[i],
                  direction,
                ),
              );
              break;
            default:
              throw new MuteSwitchError(`${tradePath} not found`, ErrorCodes.tradePathIsNotSupported);
          }
        }
      }
    }

    if (direction === TradeDirection.input) {
      return result.sort((a, b) => {
        if (new BigNumber(a.expectedConvertQuote).isGreaterThan(b.expectedConvertQuote)) {
          return -1;
        }
        return new BigNumber(a.expectedConvertQuote).isLessThan(b.expectedConvertQuote) ? 1 : 0;
      });
    } else {
      return result.sort((a, b) => {
        if (new BigNumber(a.expectedConvertQuote).isLessThan(b.expectedConvertQuote)) {
          return -1;
        }
        return new BigNumber(a.expectedConvertQuote).isGreaterThan(b.expectedConvertQuote) ? 1 : 0;
      });
    }
  }

  /**
   * Build up the route quote for erc20 > eth (not shared with other method for safety reasons)
   * @param callReturnContext The call return context
   * @param routeContext The route context
   * @param direction The direction you want to get the quote from
   */
  private buildRouteQuoteForErc20ToErc20(
    amountToTrade: BigNumber,
    callReturnContext: CallReturnContext,
    routeContext: RouteContext,
    direction: TradeDirection,
  ): RouteQuote {
    const convertQuoteUnformatted = this.getConvertQuoteUnformatted(callReturnContext, direction);

    const convertQuoteInfoUnformatted = this.getConvertQuoteInfoUnformatted(callReturnContext, direction);

    const expectedConvertQuote =
      direction === TradeDirection.input
        ? convertQuoteUnformatted.shiftedBy(this._toToken.decimals * -1).toFixed(this._toToken.decimals)
        : convertQuoteUnformatted.shiftedBy(this._fromToken.decimals * -1).toFixed(this._fromToken.decimals);

    const expectedConvertQuoteOrTokenAmountInMaxWithSlippage =
      this.getExpectedConvertQuoteOrTokenAmountInMaxWithSlippage(expectedConvertQuote, direction);

    const tradeExpires = this.generateTradeDeadlineUnixTime();

    const routeQuoteTradeContext: RouteQuoteTradeContext = {
      liquidityProviderFee: [0],
      routePathArray: callReturnContext.methodParameters[1],
    };

    return {
      expectedConvertQuote,
      expectedConvertQuoteOrTokenAmountInMaxWithSlippage,
      transaction: null,
      tradeExpires,
      routePathArrayTokenMap: callReturnContext.methodParameters[1].map((c: string) => {
        return this.allTokens.find((t) => t.contractAddress === c);
      }),
      expectedAmounts: callReturnContext.returnValues[0],
      routeText: callReturnContext.methodParameters[1]
        .map((c: string) => {
          return this.allTokens.find((t) => t.contractAddress === c)!.symbol;
        })
        .join(" > "),
      // route array is always in the 1 index of the method parameters
      routePathArray: callReturnContext.methodParameters[1],
      liquidityProviderFee: convertQuoteInfoUnformatted.fees,
      stable: convertQuoteInfoUnformatted.stable,
      quoteDirection: direction,
    };
  }

  /**
   * Build up the route quote for eth > erc20 (not shared with other method for safety reasons)
   * @param callReturnContext The call return context
   * @param routeContext The route context
   * @param direction The direction you want to get the quote from
   */
  private buildRouteQuoteForEthToErc20(
    amountToTrade: BigNumber,
    callReturnContext: CallReturnContext,
    routeContext: RouteContext,
    direction: TradeDirection,
  ): RouteQuote {
    const convertQuoteUnformatted = this.getConvertQuoteUnformatted(callReturnContext, direction);

    const convertQuoteInfoUnformatted = this.getConvertQuoteInfoUnformatted(callReturnContext, direction);

    const expectedConvertQuote =
      direction === TradeDirection.input
        ? convertQuoteUnformatted.shiftedBy(this._toToken.decimals * -1).toFixed(this._toToken.decimals)
        : new BigNumber(formatEther(convertQuoteUnformatted)).toFixed(this._fromToken.decimals);

    const expectedConvertQuoteOrTokenAmountInMaxWithSlippage =
      this.getExpectedConvertQuoteOrTokenAmountInMaxWithSlippage(expectedConvertQuote, direction);

    const tradeExpires = this.generateTradeDeadlineUnixTime();
    const routeQuoteTradeContext: RouteQuoteTradeContext = {
      liquidityProviderFee: [0],
      routePathArray: callReturnContext.methodParameters[1],
    };

    return {
      expectedConvertQuote,
      expectedConvertQuoteOrTokenAmountInMaxWithSlippage,
      transaction: null,
      tradeExpires,
      routePathArrayTokenMap: callReturnContext.methodParameters[1].map((c: string, index: number) => {
        const token = deepClone(this.allTokens.find((t) => t.contractAddress === c)!);
        if (index === 0) {
          return turnTokenIntoEthForResponse(token, this._settings?.customNetwork?.nativeCurrency);
        }

        return token;
      }),
      expectedAmounts: callReturnContext.returnValues[0],
      routeText: callReturnContext.methodParameters[1]
        .map((c: string, index: number) => {
          if (index === 0) {
            return this.getNativeTokenSymbol();
          }
          return this.allTokens.find((t) => t.contractAddress === c)!.symbol;
        })
        .join(" > "),
      // route array is always in the 1 index of the method parameters
      routePathArray: callReturnContext.methodParameters[1],
      liquidityProviderFee: convertQuoteInfoUnformatted.fees,
      stable: convertQuoteInfoUnformatted.stable,
      quoteDirection: direction,
    };
  }

  /**
   * Build up the route quote for erc20 > eth (not shared with other method for safety reasons)
   * @param callReturnContext The call return context
   * @param routeContext The route context
   * @param direction The direction you want to get the quote from
   */
  private buildRouteQuoteForErc20ToEth(
    amountToTrade: BigNumber,
    callReturnContext: CallReturnContext,
    routeContext: RouteContext,
    direction: TradeDirection,
  ): RouteQuote {
    const convertQuoteUnformatted = this.getConvertQuoteUnformatted(callReturnContext, direction);

    const convertQuoteInfoUnformatted = this.getConvertQuoteInfoUnformatted(callReturnContext, direction);

    const expectedConvertQuote =
      direction === TradeDirection.input
        ? new BigNumber(formatEther(convertQuoteUnformatted)).toFixed(this._toToken.decimals)
        : convertQuoteUnformatted.shiftedBy(this._fromToken.decimals * -1).toFixed(this._fromToken.decimals);

    const expectedConvertQuoteOrTokenAmountInMaxWithSlippage =
      this.getExpectedConvertQuoteOrTokenAmountInMaxWithSlippage(expectedConvertQuote, direction);

    const tradeExpires = this.generateTradeDeadlineUnixTime();
    const routeQuoteTradeContext: RouteQuoteTradeContext = {
      liquidityProviderFee: [0],
      routePathArray: callReturnContext.methodParameters[1],
    };

    return {
      expectedConvertQuote,
      expectedConvertQuoteOrTokenAmountInMaxWithSlippage,
      transaction: null,
      tradeExpires,
      routePathArrayTokenMap: callReturnContext.methodParameters[1].map((c: string, index: number) => {
        const token = deepClone(this.allTokens.find((t) => t.contractAddress === c)!);
        if (index === callReturnContext.methodParameters[1].length - 1) {
          return turnTokenIntoEthForResponse(token, this._settings?.customNetwork?.nativeCurrency);
        }

        return token;
      }),
      expectedAmounts: callReturnContext.returnValues[0],
      routeText: callReturnContext.methodParameters[1]
        .map((c: string, index: number) => {
          if (index === callReturnContext.methodParameters[1].length - 1) {
            return this.getNativeTokenSymbol();
          }
          return this.allTokens.find((t) => t.contractAddress === c)!.symbol;
        })
        .join(" > "),
      // route array is always in the 1 index of the method parameters
      routePathArray: callReturnContext.methodParameters[1],
      liquidityProviderFee: convertQuoteInfoUnformatted.fees,
      stable: convertQuoteInfoUnformatted.stable,
      quoteDirection: direction,
    };
  }

  /**
   * Get the convert quote unformatted from the call return context
   * @param callReturnContext The call return context
   * @param direction The direction you want to get the quote from
   */
  private getConvertQuoteUnformatted(callReturnContext: CallReturnContext, direction: TradeDirection): BigNumber {
    if (direction === TradeDirection.input) {
      return new BigNumber(callReturnContext.returnValues[0][callReturnContext.returnValues[0].length - 1]);
    } else {
      return new BigNumber(callReturnContext.returnValues[0][0]);
    }
  }

  private getConvertQuoteInfoUnformatted(
    callReturnContext: CallReturnContext,
    direction: TradeDirection,
  ): {stable: boolean[]; fees: number[]} {
    return {
      stable: callReturnContext.returnValues[1],
      fees: callReturnContext.returnValues[2].map((c: any) => new BigNumber(c).toNumber()),
    };
  }

  /**
   * Work out the expected convert quote taking off slippage
   * @param expectedConvertQuote The expected convert quote
   */
  private getExpectedConvertQuoteOrTokenAmountInMaxWithSlippage(
    expectedConvertQuote: string,
    tradeDirection: TradeDirection,
  ): string {
    const decimals = tradeDirection === TradeDirection.input ? this._toToken.decimals : this._fromToken.decimals;

    return new BigNumber(expectedConvertQuote)
      .minus(new BigNumber(expectedConvertQuote).times(this._settings.slippage).toFixed(decimals))
      .toFixed(decimals);
  }

  /**
   * Format amount to trade into callable formats
   * @param amountToTrade The amount to trade
   * @param direction The direction you want to get the quote from
   */
  private formatAmountToTrade(amountToTrade: BigNumber, direction: TradeDirection): string {
    amountToTrade = new BigNumber(amountToTrade);

    switch (this.tradePath()) {
      case TradePath.ethToErc20:
        if (direction == TradeDirection.input) {
          const amountToTradeWei = parseEther(amountToTrade);
          return hexlify(amountToTradeWei);
        } else {
          return hexlify(amountToTrade.times(Math.pow(10, this._toToken.decimals)));
        }
      case TradePath.erc20ToEth:
        if (direction == TradeDirection.input) {
          return hexlify(amountToTrade.times(Math.pow(10, this._fromToken.decimals)));
        } else {
          const amountToTradeWei = parseEther(amountToTrade);
          return hexlify(amountToTradeWei);
        }
      case TradePath.erc20ToErc20:
        if (direction == TradeDirection.input) {
          return hexlify(amountToTrade.times(Math.pow(10, this._fromToken.decimals)));
        } else {
          return hexlify(amountToTrade.times(Math.pow(10, this._toToken.decimals)));
        }
      default:
        throw new MuteSwitchError(
          `Internal trade path ${this.tradePath()} is not supported`,
          ErrorCodes.tradePathIsNotSupported,
        );
    }
  }

  /**
   * Get the trade path
   */
  private tradePath(): TradePath {
    return getTradePath(324, this._fromToken, this._toToken, this._settings.customNetwork?.nativeWrappedTokenInfo);
  }

  private get allTokens(): Token[] {
    return [this._fromToken, this._toToken, ...this.allMainTokens];
  }

  private get allMainTokens(): Token[] {
    if (
      //this._ethersProvider.provider.network.chainId === ChainId.ZKSYNC_ERA ||
      this._settings.customNetwork
    ) {
      const tokens: (Token | undefined)[] = [
        this.USDCTokenForConnectedNetwork,
        this.WETHTokenForConnectedNetwork,
        this.WBTCTokenForConnectedNetwork,
        this.USDTTokenForConnectedNetwork,
        this.KOITokenForConnectedNetwork,
        this.ZKSYNCTokenForConnectedNetwork,
      ];

      return tokens.filter((t) => t !== undefined) as Token[];
    }

    return [this.WETHTokenForConnectedNetwork];
  }

  private get mainCurrenciesPairsForFromToken(): Token[][] {
    const pairs = [
      [this._fromToken, this.WETHTokenForConnectedNetwork],
      [this._fromToken, this.USDCTokenForConnectedNetwork!],
      [this._fromToken, this.USDTTokenForConnectedNetwork!],
      [this._fromToken, this.KOITokenForConnectedNetwork!],
      [this._fromToken, this.ZKSYNCTokenForConnectedNetwork!],
    ];

    return pairs.filter((t) => t[0].contractAddress !== t[1].contractAddress);
  }

  private get mainCurrenciesPairsForToToken(): Token[][] {
    const pairs: Token[][] = [
      [this.WETHTokenForConnectedNetwork, this._toToken],
      [this.USDCTokenForConnectedNetwork!, this._toToken],
      [this.USDTTokenForConnectedNetwork!, this._toToken],
      [this.KOITokenForConnectedNetwork!, this._toToken],
      [this.ZKSYNCTokenForConnectedNetwork!, this._toToken],
    ];

    return pairs.filter((t) => t[0].contractAddress !== t[1].contractAddress);
  }

  private get mainCurrenciesPairsForUSDC(): Token[][] {
    if (this._settings.customNetwork) {
      const pairs: (Token | undefined)[][] = [
        [this.USDCTokenForConnectedNetwork, this.WETHTokenForConnectedNetwork],
        [this.USDCTokenForConnectedNetwork, this.USDTTokenForConnectedNetwork],
      ];

      return this.filterUndefinedTokens(pairs);
    }

    return [];
  }

  private get mainCurrenciesPairsForUSDT(): Token[][] {
    if (this._settings.customNetwork) {
      const pairs: (Token | undefined)[][] = [
        [this.USDTTokenForConnectedNetwork, this.WETHTokenForConnectedNetwork],
        [this.USDTTokenForConnectedNetwork, this.USDCTokenForConnectedNetwork],
      ];

      return this.filterUndefinedTokens(pairs);
    }

    return [];
  }

  private get mainCurrenciesPairsForKOI(): Token[][] {
    if (this._settings.customNetwork) {
      const pairs: (Token | undefined)[][] = [
        [this.KOITokenForConnectedNetwork, this.WETHTokenForConnectedNetwork],
        [this.KOITokenForConnectedNetwork, this.USDTTokenForConnectedNetwork],
        [this.KOITokenForConnectedNetwork, this.ZKSYNCTokenForConnectedNetwork],
      ];

      return this.filterUndefinedTokens(pairs);
    }

    return [];
  }

  private get mainCurrenciesPairsForZKSYNC(): Token[][] {
    if (this._settings.customNetwork) {
      const pairs: (Token | undefined)[][] = [
        [this.ZKSYNCTokenForConnectedNetwork, this.WETHTokenForConnectedNetwork],
        [this.ZKSYNCTokenForConnectedNetwork, this.KOITokenForConnectedNetwork],
      ];

      return this.filterUndefinedTokens(pairs);
    }

    return [];
  }

  private get mainCurrenciesPairsForWBTC(): Token[][] {
    if (
      //this._ethersProvider.provider.network.chainId === ChainId.ZKSYNC_ERA ||
      this._settings.customNetwork
    ) {
      const tokens: (Token | undefined)[][] = [[this.WBTCTokenForConnectedNetwork, this.WETHTokenForConnectedNetwork]];

      return this.filterUndefinedTokens(tokens);
    }

    return [];
  }

  private get mainCurrenciesPairsForWETH(): Token[][] {
    if (
      //this._ethersProvider.provider.network.chainId === ChainId.ZKSYNC_ERA ||
      this._settings.customNetwork
    ) {
      const tokens: (Token | undefined)[][] = [
        [this.WETHTokenForConnectedNetwork, this.USDCTokenForConnectedNetwork],
        [this.WETHTokenForConnectedNetwork, this.WBTCTokenForConnectedNetwork],
        [this.WETHTokenForConnectedNetwork, this.USDTTokenForConnectedNetwork],
        [this.WETHTokenForConnectedNetwork, this.KOITokenForConnectedNetwork],
        [this.WETHTokenForConnectedNetwork, this.ZKSYNCTokenForConnectedNetwork],
      ];

      return this.filterUndefinedTokens(tokens);
    }

    return [];
  }

  private filterUndefinedTokens(tokens: (Token | undefined)[][]): Token[][] {
    return tokens.filter((t) => t[0] !== undefined && t[1] !== undefined) as Token[][];
  }

  private get USDCTokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      return this._settings.customNetwork.baseTokens?.usdc;
    }

    return USDC.token(324);
  }

  private get USDTTokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      return this._settings.customNetwork.baseTokens?.usdt;
    }

    return USDT.token(324);
  }

  private get KOITokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      //return this._settings.customNetwork.baseTokens?.koi;
    }

    return KOI.token(324);
  }

  private get ZKSYNCTokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      //return this._settings.customNetwork.baseTokens?.koi;
    }

    return ZKSYNC.token(324);
  }

  private get MUTETokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      //return this._settings.customNetwork.baseTokens?.koi;
    }

    return MUTE.token(324);
  }

  private get WETHTokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      return this._settings.customNetwork.nativeWrappedTokenInfo;
    }

    return WETHContract.token(324);
  }

  private get WBTCTokenForConnectedNetwork() {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      return this._settings.customNetwork.baseTokens?.wbtc;
    }

    return WBTC.token(324);
  }

  private getNativeTokenSymbol(): string {
    if (this._settings.customNetwork && this._settings.customNetwork.baseTokens) {
      return this._settings.customNetwork.nativeCurrency.symbol;
    }

    return ETH_SYMBOL;
  }
}
