import React, {ReactNode} from "react";
import {WalletHook} from "../web3/walletHook";
import {Provider} from "react-redux";
import {store} from "../state";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {useEthersProvider} from "./ethers";
import {createWeb3Modal} from "@web3modal/wagmi/react";
import {http, createConfig, WagmiProvider, useAccount} from "wagmi";
import {zkSync} from "wagmi/chains";
import {walletConnect, injected, coinbaseWallet, safe} from "wagmi/connectors";
import { useAnalyticsTracking } from "./analytics";
import { CoreProvider } from "../core";
import { type Chain } from 'viem'

export const InfraApi = "https://zksync-mainnet.g.alchemy.com/v2/9YtS7FpBYVwyR_icRwyIBzgZDZFQ4BCD";
export const sophonsepolia = {
  id: 531050104,
  name: 'Sophon Sepolia',
  nativeCurrency: { name: 'Sophon', symbol: 'SOPH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.testnet.sophon.xyz'] },
  },
  blockExplorers: {
    default: { name: 'Explorer', url: 'https://explorer.testnet.sophon.xyz/' },
  },
  contracts: {
    /*
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    },
    ensUniversalResolver: {
      address: '0xE4Acdd618deED4e6d2f03b9bf62dc6118FC9A4da',
      blockCreated: 16773775,
    },
    */
    multicall3: {
      address: '0x83c04d112adedA2C6D9037bb6ecb42E7f0b108Af',
      blockCreated: 1,
    },
  },
} as const satisfies Chain
//walletconnectv2 id
const projectId = "3663eb9da4e7acb973bb7adc3c5b8a4c";
// 2. Create wagmiConfig
const metadata = {
  name: "Koi Finance",
  description: "A zkRollup DeFi ecosystem",
  url: "https://app.koi.finance",
  icons: ["https://preview.koi.finance/static/media/koi.b19d3d1ff21069b85c9a.png"],
  verifyUrl: "https://app.koi.finance",
};

//@ts-ignore
export const wagmiConfig = createConfig({
  chains: [zkSync, sophonsepolia],
  transports: {
    [zkSync.id]: http(),
    [sophonsepolia.id]: http(),
  },
  connectors: [
    walletConnect({projectId, metadata, showQrModal: false, isNewChainsStale: false}),
    injected({shimDisconnect: true}),
    safe({}),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    }),
  ],
});

// 3. Create modal
var modal = createWeb3Modal({
  wagmiConfig,
  projectId,
  defaultChain: zkSync,
  chainImages: {
    324: "https://raw.githubusercontent.com/bxpana/zksync-community-brand-assets/main/zkSync/zkSync%20Era%E2%88%8E%20/Icons/icon_zkSync_Era.png",
  },
});

const queryClient = new QueryClient({
  /*
  defaultOptions:{
    queries: {
      refetchInterval: 1_000 * 5, // 5 seconds ,
    }
  }
  */
});

interface ConnectionProviderProps {
  children: ReactNode;
}

export const Providers = ({children}: ConnectionProviderProps) => {
  useAnalyticsTracking();

  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(true);
  }, []);

  return ready ? (
    <WagmiProvider config={wagmiConfig}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <WalletHook>
            <CoreProvider>
              {children}
            </CoreProvider>
          </WalletHook>
        </QueryClientProvider>
      </Provider>
    </WagmiProvider>
  ) : null;
};

function Tracer() {
  const [chainDelayed, setChainDelayed] = React.useState(store.getState().chainDelayed);
  const {address} = useAccount();
  const provider = useEthersProvider();
  const shouldTrace = false;

  React.useEffect(() => {
    if (shouldTrace) {
      provider?.on("debug", trace);
    }
    return () => {
      provider?.off("debug", trace);
      provider?.off("debug", trace);
    };
  }, [provider, shouldTrace]);

  React.useEffect(() => {
    if (provider && address) {
      let _store = store.getState();
      setChainDelayed(_store.chainDelayed);
    }
    return () => {};
  }, [address]);

  return <></>;
}

function trace(event: any) {
  if (event.action !== "request") return;
  const {method, id, params} = event.request;
  console.groupCollapsed(method, id);
  console.debug(params);
  console.groupEnd();
}
